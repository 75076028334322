<template>
  <v-container class="pt-0">
    <router-view></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from '@/plugins/axios'

export default {
  name: 'Index.vue',
  computed: {
    ...mapGetters(['drawer', 'titleAppBar'])
  },
  methods: {
    ...mapActions(['setTitleAppBar']),
    async fetchDispositivoByCodigo(dispositivoCodigo) {
      const dispositivoResponse = await axios.get(`api/iot/dispositivos/${dispositivoCodigo}/`)
      return dispositivoResponse.data
    },
    async updateCurrentTitle() {
      const currentDispositivo = await this.fetchDispositivoByCodigo(this.$route.params.codigo)
      let title = `Dispositivo ${currentDispositivo.nombre}`
      if (this.$route.name === 'Resumen') {
        title = `Resumen de Dispositivo ${currentDispositivo.nombre}`
      }
      this.setTitleAppBar(title)
    }
  },
  watch: {
    async '$route.params.codigo'() {
      await this.updateCurrentTitle()
    }
  },
  async mounted() {
    await this.updateCurrentTitle()
  }
}
</script>

<style scoped lang="scss">


</style>
